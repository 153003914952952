import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { logErrorToVercel } from './initErrorLogger'

/**
 * Log api errors to Vercel
 */
export const ErrorMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            const metaArg = action.meta?.arg as { endpointName?: string, originalArgs?: any } || {};
            const args = JSON.stringify(metaArg.originalArgs)?.slice(0, 100);
            const error = {
                type: action.type,
                payload: action.payload,
                endpointName: metaArg.endpointName,
                args,
                error: action.error,
            }
            logErrorToVercel("redux", error)
        }
        return next(action)
    }