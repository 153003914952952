import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import security from './security';
const { getLoginWithRedirect } = security;

/**
 * Logout user if they are not authenticated
 */
export const UnAuthMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            // Check for 401 or 403 status codes and log the user out
            if (action.payload &&
                typeof action.payload === 'object' &&
                'status' in action.payload &&
                (action.payload.status === 401)) {
                getLoginWithRedirect()()
            }
        }
        return next(action)
    }