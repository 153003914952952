import React, { ReactElement, ReactNode } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";

export default function Auth0Apollo({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const { getAccessTokenSilently } = useAuth0();
  // TODO: Error handling?

  const client = React.useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });
    const httpLink = createHttpLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }, [getAccessTokenSilently]);

  return <ApolloProvider client={client}> {children} </ApolloProvider>;
}
