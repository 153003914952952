import security from "./security";

export const logErrorToVercel = (originator: string, message: any, source?: string, lineno?: number, colno?: number, error?: any) => {
    const user = security.getUser();
    const email = user?.email;
    const path = typeof window === 'undefined' ? '' : window.location.pathname;
    const body = JSON.stringify({ email, path, originator, message, source, lineno, colno, error })
    fetch('/api/error', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body,
    }).catch((err) => console.error('Failed to log error:', err));
};

export const initErrorLogging = () => {
    if (typeof window === 'undefined') return;
    window.onerror = (message, source, lineno, colno, error) => {
        logErrorToVercel("window", message, source, lineno, colno, error)
    };
};  