import { GetTokenSilentlyOptions, Auth0ContextInterface } from '@auth0/auth0-react';

let loginWithRedirect: Auth0ContextInterface['loginWithRedirect'];
let getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>;
let user: Auth0ContextInterface['user'];

const security = {
  getLoginWithRedirect: () => loginWithRedirect,
  setLoginWithRedirect: (func: Auth0ContextInterface['loginWithRedirect']) => (loginWithRedirect = func),
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>) =>
    (getAccessTokenSilently = func),
  getUser: () => user,
  setUser: (func: Auth0ContextInterface['user']) => (user = func),
};

export default security;
